import { dispatchAction } from '../dispatcher/AppDispatcher';
import ScrollWidgetConstants from '../constants/actionTypes/ScrollWidgetConstants';

export function scrollOlaWidget() {
  // TODO: Add actual debugging logging

  dispatchAction({
    type: ScrollWidgetConstants.SCROLL_WIDGET,
    data: 'ola'
  });

  typeof window !== 'undefined' && window.dispatchEvent(new Event('olaScroll'));
}
